/* EffortCalculator.css */

.generatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
    background-color: #f4f4f9;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .generatorContainer .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .generatorContainer .form-group {
    margin-bottom: 15px;
    text-align: left;
    width: 300px;
  }
  
  .generatorContainer label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .generatorContainer .dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .generatorContainer .form-fields {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .generatorContainer .input-group {
    margin-bottom: 10px;
  }
  
  .generatorContainer .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .generatorContainer .submit-button {
    width: 300px;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .generatorContainer .submit-button.enabled {
    background-color: rgb(243, 110, 43);
    color: white;
  }
  
  .generatorContainer .submit-button.enabled:hover {
    background-color: rgb(243, 110, 43)
  }
  
  .generatorContainer .submit-button.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  
  .generatorContainer .result {
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
    color: rgb(243, 110, 43)
  }
  