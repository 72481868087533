/* User Profile Container */
.user-profile {
    width: 300px;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
    transition: 0.3s ease-in-out;
  }
  
  /* Profile Picture */
  .profile-picture {
    width: 80px;
    height: 80px;
    background: #f57c00;
    color: white;
    font-size: 32px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 15px;
  }
  
  /* User Name */
  .user-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  /* User Email */
  .user-email {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Email Icon */
  .email-icon {
    margin-right: 8px;
  }
  
  /* Buttons */
  .profile-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .edit-btn,
  .logout-btn {
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .edit-btn {
    background: #f57b00;
    color: white;
  }
  
  .edit-btn:hover {
    background: #f56e00;
  }
  
  .logout-btn {
    background: #dc3545;
    color: white;
  }
  
  .logout-btn:hover {
    background: #a71d2a;
  }
  
  /* ----- */
  .user-info-card {
    width: 500px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: Arial, sans-serif;
}

.user-avatar img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #f57c00;
}

.user-details {
    flex-grow: 1;
}

.user-details h2 {
    font-size: 18px;
    color: #333;
    margin: 0 0 5px;
}

.user-details p {
    font-size: 14px;
    color: #555;
    margin: 3px 0;
}

.email {
    color: #007bff;
    font-weight: bold;
}

.company {
    color: #28a745;
}

.contact {
    color: #d9534f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .user-info-card {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}


.dFlex {
    display: flex;
}
.profileContainer {
    width: calc(100% - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
}