/* Header.css */
.custom-dropdown .dropdown-toggle::after {
    color: rgb(128, 48, 0) !important; 
}
.custom-dropdown .dropdown-item:hover {
    background-color: rgb(148, 74, 29); 
    color: #fff; 
}

/* Adjust dropdowns for small screens */
@media (max-width: 767px) {
    .custom-dropdown .dropdown-menu {
        font-size: 14px;
        text-align: center;
    }

    .navbar-nav .nav-link {
        margin: 10px 0;
    }

    /* Adjust logo for smaller screens */
    .navbar-brand img {
        width: 150px;
        height: 40px;
    }
}

/* For medium screens */
@media (max-width: 991px) {
    .navbar-brand img {
        width: 180px;
        height: 45px;
    }
}

/* For large screens */
@media (min-width: 992px) {
    .navbar-brand img {
        width: 200px;
        height: 50px;
    }
}




