.bg-container-fluid {
    background-color: rgb(236, 219, 212);
  }
  
  .kt-heading {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .kt-text {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .training-button {
    display: flex;
    flex-wrap: wrap;
  }
  
  .katalon-image {
    max-width: 100%;
    height: auto;
  }
  
  .kt-list {
    margin-top: 20px;
  }
  
  .kt-list li {
    margin-bottom: 10px;
  }
  
  .btn-primary {
    background-color: orangered;
    border-color: orangered;
  }
  
  .btn-primary:hover {
    background-color: #000;
    border-color: #000;
  }
  
  .btn-outline-dark:hover {
    background-color: #8d8787;
    color: #fff;
  }
  
  .btn-warning {
    background-color: #F36E2B;
    border: none;
  }
  
  .btn-warning:hover {
    background-color: #000;
    color: #fff;
  }
  