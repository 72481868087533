/* Title styling */
  .our-team-title {
    text-align: center;
    font-size: 40px;
    color: #333;
    margin-bottom: 50px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 600;
  }
  
  /* Grid layout */
  .our-team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  /* Card styling */
  .our-team-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Image container for fixed aspect ratio */
  .our-team-image-container{
    width: 100%;
    height: 250px;
    overflow: hidden;
  }
  
  /* Image styling without border-radius */
  .our-team-profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
    border-radius: 0; 
  }
  
  .our-team-card:hover .our-team-profile-image {
    transform: scale(1.1);
  }
  
  /* Card content styling */
  .our-team-content {
    padding: 20px;
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
  }
  
  .our-team-content h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .our-team-content p {
    margin: 5px 0;
    color: #777;
  }
  
  /* Social Icons */
  .social-icons {
    margin-top: 10px;
  }
  
  .social-link {
    margin: 0 10px;
    color: #333;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .social-link:hover {
    color: #0077b5; 
  }
  
  /* Card hover effect */
  .our-team-card:hover {
    transform: scale(1.05);
  }
  