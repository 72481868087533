/* General styles for the register form */
.register-container {
    background: url('https://coloredbrain.com/wp-content/uploads/2016/07/login-background.jpg') no-repeat center center/cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-box {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    padding: 30px;
}

h2 {
    color: #333;
}

.form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 12px;
    margin-top: 2px;
}

.btn-primary {
    background-color: #4a90e2;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #357ab8;
}

.password-toggle-icon {
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    position: absolute;
    top: 27px;
    right: 10px;
}

.password-toggle-icon:hover {
    color: #000;
}

@media (max-width: 768px) {
    .register-box {
        padding: 20px;
        max-width: 90%;
    }
}
