/* General Styles */
.about-section {
    padding: 50px 0;
    background-color: #f4f9f9;
  }
  
  .about-heading {
    font-family: 'Georgia', serif;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .about-image {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
  
  .profile-section {
    margin: 70px 0;
  }
  
  .profile-section h5 {
    font-family: 'Georgia', serif;
    font-weight: 600;
    max-width: 800px;
    margin: auto;
  }
  
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
  }
  
  .profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .about-image {
      max-width: 100%;
      height: auto;
    }
    
    .profile-section h5 {
      max-width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .about-heading {
      font-size: 1.5rem;
    }
  
    .profile-image {
      width: 80px;
      height: 80px;
    }
  }
  